import * as $ from 'jquery';

$(() => {
    let timeZoneId: string = Intl.DateTimeFormat().resolvedOptions().timeZone; // IANA TimeZoneId
    $('.set-timezonid').val(timeZoneId);

    $('#forgotpassword').click(function () {
        var baseUrl = $(this).attr('href');
        var email = $("input[name=Email]").val();

        window.location.href = baseUrl + '?email=' + email;
    });

    $('#Password').on('input', function () {
        $(".validation-summary-errors").hide();
    });
});